.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.App-header {
  background-color: #282c34;
  padding: 15px 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App-header h1 {
  margin: 0;
  font-size: 1.5em;
}

.App-header button {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
}

.main-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.panel {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.left-panel {
  background-color: #f0f0f0;
}

.right-panel {
  background-color: #e0e0e0;
}

form {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

input[type="text"] {
  flex: 1;
  max-width: 450px; /* Adjust this value as needed */
  padding: 10px;
  margin-bottom: 0; /* Remove bottom margin */
}

button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  white-space: nowrap; /* Prevent button text from wrapping */
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #45a049;
}

button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.gallery-item {
  cursor: pointer;
}

.gallery-item img {
  width: 100%;
  height: auto;
}

.generated-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.generated-content img {
  max-width: 100%;
  max-height: 60vh;
  object-fit: contain;
  margin-bottom: 20px;
}

.generated-prompt {
  text-align: center;
  margin-bottom: 20px;
}

.about-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.about-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
}

.about-content p:last-child {
  margin-top: 20px;
  font-style: italic;
  border-top: 1px solid #ccc;
  padding-top: 10px;
}

.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
}
